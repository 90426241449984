import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDqB1uzWwUVyyermiqiwFh1QIGpeKozCRo",
  authDomain: "prestige-capital-gains.firebaseapp.com",
  projectId: "prestige-capital-gains",
  storageBucket: "prestige-capital-gains.appspot.com",
  messagingSenderId: "869501431995",
  appId: "1:869501431995:web:20208864797e1ab4a0b99e",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBGq25Hh0nt1zy-G4L32-hYypt6LDS9ULc",
//   authDomain: "finance-test-5f328.firebaseapp.com",
//   projectId: "finance-test-5f328",
//   storageBucket: "finance-test-5f328.appspot.com",
//   messagingSenderId: "261981000578",
//   appId: "1:261981000578:web:ee913ada0c961a2803759b",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
